import React from "react";
import { ImpulseSpinner, CubeSpinner, ClapSpinner } from "react-spinners-kit";

import "./Loader.scss";

export class Loader extends React.Component {
    render() {
        return (
            <span className="page_loader_wrapper center">
                <CubeSpinner size={25} frontColor="#E30613" backColor="#E30613" sizeUnit="px" />
            </span>
        );
    }
}

export class OrderLoader extends React.Component {
    render() {
        return (
            <span className="page_loader_wrapper">
                <ClapSpinner size={25} frontColor="#E30613" backColor="#E30613" sizeUnit="px" />
            </span>
        );
    }
}

export class ButtonLoader extends React.Component {
    render() {
        return (
            <div className="button_loader">
                <ImpulseSpinner size={30} frontColor="#FFFFFF" backColor="#733F24" sizeUnit="px" />
            </div>
        );
    }
}
